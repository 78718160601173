import logo from './logo.svg';
import './App.css';
import Root from './main/shared/Root/Root';


function App() {
  return (
    <Root />
  );
}

export default App;
