
const typeSubValidation = {

    member: 'Member is required',
    category: 'Category is required',
    period: 'Period is requied',
    amount: 'Amount is required',
    description: 'Description is required',
    fee: 'Fee is required',
    time_payment: 'Payment time is required',


}
export default typeSubValidation;