import React from 'react';
import PropTypes from 'prop-types';
import './EditBooking.css';

const EditBooking = () => (
  <div className="EditBooking">
    EditBooking Component
  </div>
);

EditBooking.propTypes = {};

EditBooking.defaultProps = {};

export default EditBooking;
