import React from 'react';
import PropTypes from 'prop-types';
import './AddBooking.css';

const AddBooking = () => (
  <div className="AddBooking">
    AddBooking Component
  </div>
);

AddBooking.propTypes = {};

AddBooking.defaultProps = {};

export default AddBooking;
